@charset "UTF-8";

@media screen {
    // Print overrides
    .show-for-print {
        display: none !important;
    }
}

@media print {
    // Print overrides
    .hide-for-print {
        display: none !important;
    }

    .small-for-print {
        max-width: 50%;
    }

    .wrapper {
        box-shadow: none;
    }

    .progress-bar {
        // We need to get the progress bar nodes to behave
        font-size: .65rem;
    }

    .breadcrumbs a {
        max-width: 500px;
    }

    table tr:nth-of-type(odd) {
        background: #fcfcfc;
    }

    table tr.alt, table tr:nth-of-type(even) {
        background: #fff;
    }

    .radio-checkbox[type=radio] + label:before {
        color: transparent;
    }

    .radio-checkbox[type=radio]:checked + label:before {
        color: #fff;
    }

    .footer,
    .breadcrumbs,
    .module__header,
    .progress-bar__item.progress-bar__item--complete::after,
    .progress-bar__item.progress-bar__item--minor::after,
    .top-bar--top-strip,
    .question.question--multichoice label,
    .draggable.draggable--answered .draggable__item,
    pre,
    .radio-checkbox__label,
    .radio-checkbox__container.radio-checkbox__container label {
        // Ensure we show background colours
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }

    iframe[src^="https://player.vimeo.com/video/"] {
        background-image: url('/assets/img/versioned/video-placeholder.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }
}
